<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formDispositivo" lazy-validation>
      <v-container fluid>
        <v-widget title="Dispositivo" noDivider>
          <div slot="widget-content">
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-text-field label="Chave" v-model="dispositivo.chave" readonly data-cy="chave" :rules="validarPreenchimento"></v-text-field>
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field label="Navegador" v-model="dispositivo.navegador" :rules="validarPreenchimento" readonly data-cy="navegador"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="Versão do Navegador" v-model="dispositivo.versao_navegador" :rules="validarPreenchimento" readonly data-cy="versao_navegador"></v-text-field>
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field label="Sistema Operacional" v-model="dispositivo.sistema_operacional" :rules="validarPreenchimento" readonly data-cy="sistema_operacional"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="Versão do Sistema Operacional" v-model="dispositivo.versao_sistema_operacional" :rules="validarPreenchimento" readonly data-cy="versao_sistema_operacional"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field label="Nome" autocomplete="off" role="presentation" v-model="dispositivo.nome" :rules="validarNome" autofocus required data-cy="nome"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea label="Observação" v-model="dispositivo.observacao" data-cy="observacao" hideDetails></v-textarea>
              </v-col>
            </v-row>
          </div>

          <template v-slot:widget-footer-action>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
              <v-btn depressed color="primary" :loading="carregando" @click="salvar" :disabled="!valid" data-cy="salvar">Salvar</v-btn>
            </v-card-actions>
          </template>
        </v-widget>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'FormularioDispositivo',
  data: () => ({
    valid: false,
    validarNome: [(v) => !!v || 'Preenchimento obrigatório'],
    validarPreenchimento: [(v) => !!v || 'Preenchimento obrigatório'],
    loading: false,
  }),

  computed: {
    ...mapState('dispositivo', {
      dispositivo: 'dispositivoAtual',
      carregando: 'carregando',
    }),

    ...mapGetters('dispositivo', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar',
    }),
  },

  methods: {
    async salvar() {
      if (this.$refs.formDispositivo.validate()) {
        try {
          await this.$store.dispatch('dispositivo/salvar');
          this.$router.push({ name: 'dispositivos' });
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        }
      }
    },

    cancelar() {
      this.$router.push({ name: 'dispositivos' });
    },
  },

  async created() {
    try {
      this.loading = true;
      let dispositivo = this.$store.state.dispositivo.dispositivoAtual;
      let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;

      if (estaEditando && (!dispositivo._id || dispositivo._id === '')) {
        this.$router.push('/dispositivos');
      }

      if (!estaEditando) {
        this.$store.commit('dispositivo/estadoInicial');
        this.$store.dispatch('dispositivo/lerInformacoes');
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },
};
</script>
